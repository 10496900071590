import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import * as Realm from "realm-web";

import LoginForm from './components/LoginForm';
import Projects from './components/Projects';
import NewProject from './components/New_Project';
import ProjectDetail from './components/Project_Detail';
import Root from './components/Root';

export const APP_ID = "avat-db-bzvgs";


const app = new Realm.App(APP_ID);

async function getValidAccessToken() {
  if (!app.currentUser) {
    await app.logIn(Realm.Credentials.anonymous());
  } else {
    await app.currentUser.refreshCustomData();
  }
  return app.currentUser.accessToken;
}

const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://asia-south1.gcp.realm.mongodb.com/api/client/v2.0/app/avat-db-bzvgs/graphql`,
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  }),
  cache: new InMemoryCache()
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/" element={<App />}>
          <Route index element={<Root />}/>
          <Route path="projects" >
            <Route index element={<Projects />} />
            <Route path='new' element={<NewProject />} />
            <Route path=':projectId' element={<ProjectDetail />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </ApolloProvider>
);