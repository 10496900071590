import { useForm } from "react-hook-form"
import Cryptojs from 'crypto-js/sha256'
import Footer from "./Footer"
import Header from "./Header"
import { useLazyQuery } from "@apollo/client"
import { USER_LOGIN } from "../utils/graphqlOperations"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

const LoginForm =()=>{
    const navigate = useNavigate();
    useEffect(() => {
        const uid = localStorage.getItem('uid')
        if(uid){
            navigate('/projects')
        }
    },[navigate])
    const {register, handleSubmit, formState: { errors }}=useForm({
        loginId:"",
        password:""
    })
    const [
        userLogin
    ] = useLazyQuery(USER_LOGIN, {
        fetchPolicy: 'network-only'
    })
    const loginTest =(data)=>{
        data.password = Cryptojs(`${data.password}GetIT@2022AVAT`).toString()
        console.log(data)
        userLogin({ variables: { query: { loginId: data.loginId, password: data.password } }})
        .then((res) => {
            const userData = res.data.user
            // console.log(res)
            if(userData === null){
                
            }else{
                localStorage.setItem("uid",`${userData.uid}`)
                navigate("/projects")
                return
            }
        })
        .catch((err) => {

        })
    }
    return(
        <>
            <Header />
            <div className="form-wrap">
                <div className="inputHolder">
                    <input type="text" placeholder="Login ID" {...register('loginId',{required:true})} />
                    {errors && errors.loginId && <span className="error">This is a required field</span>}
                </div>
                <div className="inputHolder">
                    <input type="password" placeholder="Password" {...register('password',{required:true})}/>
                    {errors && errors.password && <span className="error">This is a required field</span>}
                </div>
                <div className="inputHolder">
                    <button onClick={handleSubmit(loginTest)}>Login</button>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default LoginForm