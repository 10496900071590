import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './App.scss'
import Footer from './components/Footer';
import Header from "./components/Header";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('uid')
    if(!isLoggedIn){
      navigate("/login")
    }
  },[navigate])
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;