import { useMutation } from "@apollo/client"
import _ from "lodash"
import moment from "moment"
import { useDeferredValue, useEffect, useState } from "react"
import { UPDATE_CSV } from "../utils/graphqlOperations"

const ProjectDetailTable = ({data,loading}) => {
    const [accounts, setAccounts] = useState([])
    const [accountsCopy, setAccountsCopy] = useState([])
    const [filterVal, setFilterVal] = useState("viewAll")
    const [groupFilter, setGroupFilterVal] = useState({
        industry: 'viewAll',
        country: 'viewAll',
        category: 'viewAll'
    })
    const [sortOrder,setSortOrder] = useState(0)

    const deferedLoad = useDeferredValue(loading)

    useEffect(() => {
        setAccounts(data.account)
        setAccountsCopy(data.account)
    },[data])

    const [updateCsv] = useMutation(UPDATE_CSV)

    const updateaccount = (e,aid) => {
        const updateAccountIndex = accounts.findIndex(el => {return el.aid === aid})
        const updatedAccount = [...accounts]
        updatedAccount.map(el => {
            delete el["__typename"]
            return el
        })
        updatedAccount[updateAccountIndex]["newBucket"] = e.target.value
        updateCsv({
            variables: {
                query:{
                    cid: data.cid
                },
                data: {
                    accounts: updatedAccount,
                    updatedDate: moment().toISOString()
                }
            }
        })
        .then(res => console.log(res))
    }

    const calMedian = (arr = []) => {
        const sortedArr = _.sortBy(arr)
        if(sortedArr.length % 2 === 0){
            return Math.round((sortedArr[Math.floor(sortedArr.length/2)] + sortedArr[Math.floor(sortedArr.length/2) - 1])/2)
        }
        return Math.round(sortedArr[Math.floor(sortedArr.length/2)])
    }

    const sort = () => {
        const sortCount = sortOrder + 1
        setSortOrder(sortCount);
        if(sortCount % 3 === 0){
            setAccountsCopy(_.intersectionBy(accounts,accountsCopy,'accountName'))
            return
        }
        if(sortCount % 3 === 1){
            setAccountsCopy(_.orderBy(accountsCopy,['accountName'],['asc']))
            return
        }
        if(sortCount % 3 === 2){
            setAccountsCopy(_.orderBy(accountsCopy,['accountName'],['desc']))
            return
        }
    }

    return <div className="project-detail-table">
        <div className="filterWrap">
            <div className="container-fluid">
                {!deferedLoad &&
                    <div className="table-wrap">
                        <div className="table-row-wrap filterWrap">
                            <div className="table-col1"></div>
                            <div className="table-col11 filterBlock">
                                <select className="filterInput" onChange={async (e) => {
                                    let filterObject = {}
                                    await setGroupFilterVal({...groupFilter,industry:e.target.value})
                                    if(groupFilter.country !== "viewAll"){
                                        filterObject["country"] = groupFilter.country
                                    }
                                    if(groupFilter.category !== "viewAll"){
                                        filterObject["newBucket"] = groupFilter.category
                                    }
                                    if(e.target.value !== "viewAll"){
                                        filterObject["industry"] = e.target.value
                                    }
                                    setAccountsCopy(_.filter(accounts,filterObject))
                                }}>
                                    <option value={"viewAll"}>Filter by Industry</option>
                                    {
                                        _.uniqBy(accounts,"industry").map(el => {
                                            return <option value={el.industry} key={el.aid}>{el.industry}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="table-col12 filterBlock">
                                <select className="filterInput" onChange={async (e) => {
                                    let filterObject = {}
                                    await setGroupFilterVal({...groupFilter,country:e.target.value})
                                    if(groupFilter.industry !== "viewAll"){
                                        filterObject["industry"] = groupFilter.industry
                                    }
                                    if(groupFilter.category !== "viewAll"){
                                        filterObject["newBucket"] = groupFilter.category
                                    }
                                    if(e.target.value !== "viewAll"){
                                        filterObject["country"] = e.target.value
                                    }
                                    setAccountsCopy(_.filter(accounts,filterObject))
                                }}>
                                    <option value={"viewAll"}>Filter by Country</option>
                                    {
                                        _.uniqBy(accounts,"country").map(el => {
                                            return <option value={el.country} key={el.aid}>{el.country}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="table-col2 colSpan2 filterBlock">
                                <select className="filterInput" onChange={async (e) => {
                                    let filterObject = {}
                                    await setFilterVal(e.target.value)
                                    await setGroupFilterVal({...groupFilter,category:e.target.value})
                                    if(groupFilter.industry !== "viewAll"){
                                        filterObject["industry"] = groupFilter.industry
                                    }
                                    if(groupFilter.country !== "viewAll"){
                                        filterObject["country"] = groupFilter.country
                                    }
                                    if(e.target.value !== "viewAll"){
                                        filterObject["newBucket"] = e.target.value
                                    }
                                    setAccountsCopy(_.filter(accounts,filterObject))
                                }}>
                                    <option value={"viewAll"}>View All</option>
                                    <option value={"One-To-One"}>One-To-One</option>
                                    <option value={"One-To-Few"}>One-To-Few</option>
                                    <option value={"One-To-Many"}>One-To-Many</option>
                                </select>
                            </div>
                            <div className="table-col4"></div>
                            <div className="table-col5">{filterVal === "One-To-Few" || filterVal === "One-To-Many" ? "Median" : ""}</div>
                            <div className="table-col6">{filterVal === "One-To-Few" || filterVal === "One-To-Many" ? `${calMedian(_.map(_.filter(accountsCopy,['newBucket', filterVal]),el => {return parseInt(Math.round(el.reputation),10)}))}%`: ""}</div>
                            <div className="table-col7">{filterVal === "One-To-Few" || filterVal === "One-To-Many" ? `${calMedian(_.map(_.filter(accountsCopy,['newBucket', filterVal]),el => {return parseInt(Math.round(el.relationship),10)}))}%`: ""}</div>
                            <div className="table-col8">{filterVal === "One-To-Few" || filterVal === "One-To-Many" ? `${calMedian(_.map(_.filter(accountsCopy,['newBucket', filterVal]),el => {return parseInt(Math.round(el.revenue),10)}))}%`: ""}</div>
                        </div>
                        <div className="table-row-wrap table-row-head">
                            <div className="table-col1">
                                Account Name
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 50 65.7" onClick={() => sort()} className={sortOrder%3 === 1 ? "asc" : sortOrder%3 === 2 ? "desc" : ""}><path style={{fillRule:"evenodd",clipRule:"evenodd"}} d="M22.8 64.8.9 43c-1.2-1.3-1.2-3.2 0-4.4 1.2-1.2 3.2-1.2 4.4 0L25 58.2l19.7-19.7c1.3-1.2 3.2-1.2 4.4 0 1.2 1.2 1.2 3.1 0 4.4L27.2 64.8C26 66 24 66 22.8 64.8z"/><path style={{fillRule:"evenodd",clipRule:"evenodd"}} d="m27.2.9 21.9 21.9c1.2 1.3 1.2 3.2 0 4.4-1.2 1.2-3.2 1.2-4.4 0L25 7.5 5.3 27.2c-1.3 1.2-3.2 1.2-4.4 0-1.2-1.2-1.2-3.1 0-4.4L22.8.9C24-.3 26-.3 27.2.9z"/></svg>
                            </div>
                            <div className="table-col11">Industry</div>
                            <div className="table-col12">Country</div>
                            <div className={_.size(_.filter(accountsCopy,['newBucket', "One-To-One"])) === 0 && filterVal !== "viewAll" ? "table-col2 disabled" : "table-col2"}>One-To-One <span className="countWrap one-one">{accountsCopy.length > 0 ? _.size(_.filter(accountsCopy,['newBucket', "One-To-One"])) : "0"}</span></div>
                            <div className={_.size(_.filter(accountsCopy,['newBucket', "One-To-Few"])) === 0 && filterVal !== "viewAll" ? "table-col3 disabled" : "table-col3"}>One-To-Few <span className="countWrap one-few">{accountsCopy.length > 0 ? _.size(_.filter(accountsCopy,['newBucket', "One-To-Few"])) : "0"}</span></div>
                            <div className={_.size(_.filter(accountsCopy,['newBucket', "One-To-Many"])) === 0 && filterVal !== "viewAll" ? "table-col4 disabled" : "table-col4"}>One-To-Many <span className="countWrap one-many">{accountsCopy.length > 0 ? _.size(_.filter(accountsCopy,['newBucket', "One-To-Many"])) : "0"}</span></div>
                            <div className={_.size(_.filter(accountsCopy,['newBucket', "Demand Gen"])) === 0 && filterVal !== "viewAll" ? "table-col5 disabled" : "table-col5"}>Demand Gen <span className="countWrap demand-gen">{accountsCopy.length > 0 ? _.size(_.filter(accountsCopy,['newBucket', "Demand Gen"])) : "0"}</span></div>
                            <div className="table-col6">Reputation</div>
                            <div className="table-col7">Relationship</div>
                            <div className="table-col8">Revenue</div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div className="container-fluid">
            {!deferedLoad &&
            <div className="table-wrap">
                {
                    accountsCopy.length > 0 && accountsCopy.map((el,i) => {
                        return (
                            <div className="table-row-wrap" key={`${data.cid}${i}`}>
                                <div className="table-col1">{el.accountName}</div>
                                <div className="table-col11">{el.industry}</div>
                                <div className="table-col12">{el.country}</div>
                                <div className={_.size(_.filter(accountsCopy,['newBucket', "One-To-One"])) === 0 && filterVal !== "viewAll" ? "table-col2 cursorDisabled" : "table-col2"}>
                                    <input type="radio" id={`table-item-1${i}`} name={`${data.cid}${i}`} checked={el.newBucket === "One-To-One" ? true : false} value="One-To-One" onChange={(e) => updateaccount(e,el.aid)} disabled={_.size(_.filter(accountsCopy,['newBucket', "One-To-One"])) === 0 && filterVal !== "viewAll" ? true : false} />
                                    <label htmlFor={`table-item-1${i}`}>
                                        <svg viewBox="0 0 17.55 13.13"> <path d="M17.11,2.56L6.54,13.13,.44,7.03c-.59-.59-.59-1.54,0-2.12,.58-.59,1.54-.59,2.12,0l3.98,3.98L14.99,.44c.59-.59,1.54-.59,2.12,0s.59,1.54,0,2.12Z" /></svg>
                                    </label>
                                </div>
                                <div className={_.size(_.filter(accountsCopy,['newBucket', "One-To-Few"])) === 0 && filterVal !== "viewAll" ? "table-col3 cursorDisabled" : "table-col3"}>
                                    <input type="radio" id={`table-item-2${i}`} name={`${data.cid}${i}`} checked={el.newBucket === "One-To-Few" ? true : false} value="One-To-Few"  onChange={(e) => updateaccount(e,el.aid)} disabled={_.size(_.filter(accountsCopy,['newBucket', "One-To-Few"])) === 0 && filterVal !== "viewAll" ? true : false} />
                                    <label htmlFor={`table-item-2${i}`}>
                                        <svg viewBox="0 0 17.55 13.13"> <path d="M17.11,2.56L6.54,13.13,.44,7.03c-.59-.59-.59-1.54,0-2.12,.58-.59,1.54-.59,2.12,0l3.98,3.98L14.99,.44c.59-.59,1.54-.59,2.12,0s.59,1.54,0,2.12Z" /></svg>
                                    </label>
                                </div>
                                <div className={_.size(_.filter(accountsCopy,['newBucket', "One-To-Many"])) === 0 && filterVal !== "viewAll" ? "table-col4 cursorDisabled" : "table-col4"}>
                                    <input type="radio" id={`table-item-3${i}`} name={`${data.cid}${i}`} checked={el.newBucket === "One-To-Many" ? true : false} value="One-To-Many"  onChange={(e) => updateaccount(e,el.aid)} disabled={_.size(_.filter(accountsCopy,['newBucket', "One-To-Many"])) === 0 && filterVal !== "viewAll" ? true : false} />
                                    <label htmlFor={`table-item-3${i}`}>
                                        <svg viewBox="0 0 17.55 13.13"> <path d="M17.11,2.56L6.54,13.13,.44,7.03c-.59-.59-.59-1.54,0-2.12,.58-.59,1.54-.59,2.12,0l3.98,3.98L14.99,.44c.59-.59,1.54-.59,2.12,0s.59,1.54,0,2.12Z" /></svg>
                                    </label>
                                </div>
                                <div className={_.size(_.filter(accountsCopy,['newBucket', "Demand Gen"])) === 0 && filterVal !== "viewAll" ? "table-col5 cursorDisabled" : "table-col5"}>
                                    <input type="radio" id={`table-item-4${i}`} name={`${data.cid}${i}`} checked={el.newBucket === "Demand Gen" ? true : false} value="Demand Gen"  onChange={(e) => updateaccount(e,el.aid)} disabled={_.size(_.filter(accountsCopy,['newBucket', "Demand Gen"])) === 0 && filterVal !== "viewAll" ? true : false} />
                                    <label htmlFor={`table-item-4${i}`}>
                                        <svg viewBox="0 0 17.55 13.13"> <path d="M17.11,2.56L6.54,13.13,.44,7.03c-.59-.59-.59-1.54,0-2.12,.58-.59,1.54-.59,2.12,0l3.98,3.98L14.99,.44c.59-.59,1.54-.59,2.12,0s.59,1.54,0,2.12Z" /></svg>
                                    </label>
                                </div>
                                <div className="table-col6">{`${Math.round(el.reputation)}%`}</div>
                                <div className="table-col7">{`${Math.round(el.relationship)}%`}</div>
                                <div className="table-col8">{`${Math.round(el.revenue)}%`}</div>
                            </div>
                        )
                    })
                }
            </div>
            }
        </div>
    </div>
}

export default ProjectDetailTable